.container {
  height: 95%;
  .buttons {
    button {
      width: fit-content;
      min-width: none;
      padding: 4px;
      aspect-ratio: 1;
    }
  }
}

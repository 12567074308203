.container {
  height: 300px;
  margin: 2rem 0 1rem 0;
  .editor {
    height: 200px;
  }
  .select {
    margin-left: 1rem;
    height: 39px;
    min-width: 100px;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(black, 0.3);
  z-index: 20; // TODO gestire z-index children root
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  position: relative;
  max-height: 90%;
  overflow: auto;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(black, 0.2);
  padding: 8px;
  border-radius: 8px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    .iconTitle {
      padding-left: 16px;
    }
  }
  .iconHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .iconTitle {
      padding-left: 16px;
      display: flex;
      align-items: center;
      .icon {
        padding-right: 8px;
      }
    }
  }
  .scrollable {
    margin: 16px;
    display: flex;
    flex-flow: column;
    overflow: auto;
  }
  .close {
    padding: 8px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: rgb(0, 166, 207) !important;
}

.rbc-time-column {
  min-height: initial;
}

.rbc-time-header {
  min-height: 99.9%;
  overflow: hidden;
}

.rbc-label.rbc-time-header-gutter {
  padding: 0;
  min-width: 0 !important;
  width: 0 !important;
}
.handled-ticket {
  .rbc-event.rbc-event-allday {
    background-color: darkgray;
  }
}
.paid-ticket {
  .rbc-event.rbc-event-allday {
    background-color: forestgreen;
  }
}
.unpaid-ticket {
  .rbc-event.rbc-event-allday {
    background-color: brown;
  }
}

.rbc-time-header.rbc-overflowing {
  margin-right: 0 !important;
  border-right: 0 !important;
}

.rbc-header + .rbc-header {
  text-transform: capitalize;
}

.rbc-header {
  span,
  span button {
    font-size: 20px;
  }
}

.rbc-toolbar {
  overflow-x: hidden;
  span {
    font-size: 20px;
  }
  span button {
    font-size: 18px;
  }
  button {
    @media (max-width: 500px) {
      padding: 0.375rem 0.75rem !important;
    }
  }
}

.rbc-agenda-table {
  thead tr :nth-child(2) {
    display: none;
  }
  tbody tr .rbc-agenda-time-cell {
    display: none;
  }
  tbody > tr + tr {
    border-color: rgba(0, 0, 0, 0.6) !important;
  }
}

.rbc-time-view {
  overflow: auto;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0))
      center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}
.rbc-time-content {
  border: none !important;
}

.carousel {
  margin: 1rem;
  min-height: 300px;
  :global(.carousel-slider) {
    min-height: 100%;
  }
  :global(.dot) {
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.9) !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  .slide {
    height: 300px;
    overflow: auto;
    div,
    p {
      margin: 0;
      text-align: start;
    }
    .footer {
      margin-top: 0.5rem;
    }
    .object {
      margin-bottom: 0.5rem;
    }
  }
}

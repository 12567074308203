.upload {
  position: relative;
  border: 4px dashed #000;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  p {
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 50px;
  }
  .fileUploaded {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    line-height: 50px;
    p {
      width: auto;
    }
  }
  input {
    position: absolute;
    margin-left: -1rem;
    padding: 0;
    width: 100%;
    height: 100%;
    top: 0;
    outline: none;
    opacity: 0;
    &.disabledInput {
      display: none;
    }
  }
}
.uploadBtn {
  margin-top: 1rem;
  color: white;
  background: #16a085;
  border: none;
  height: 35px;
  border-radius: 4px;
  border-bottom: 4px solid #117a60;
  transition: all 0.2s ease;
  outline: none;
  &:hover:not(:disabled) {
    background: #149174;
    color: #0c5645;
  }
  &:active {
    border: 0;
  }
  &:disabled {
    background-color: #1f4840;
  }
}

.error {
  color: rgb(145, 41, 41);
}

.form {
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  margin: 8px;
}

.form__field {
  margin: 15px 0;
  font-size: 16px;
  padding: 10px;
  width: calc(100% - 20px);
  border: 1px solid rgba(10, 180, 180, 1);
  border-top: none;
  border-left: none;
  border-right: none;
  background: rgba(20, 20, 20, 0.2);
  color: black;
  outline: none;
}

.errorBtn {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.container {
  padding: 1rem;
  .form__field {
    margin: 15px 0;
    font-size: 16px;
    padding: 10px;
    width: calc(100% - 1rem);
    border: 1px solid rgba(10, 180, 180, 1);
    border-top: none;
    border-left: none;
    border-right: none;
    background: rgba(20, 20, 20, 0.1);
    color: black;
    outline: none;
  }
}

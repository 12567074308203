.container {
  width: 800px;
  display: flex;
  flex-direction: column;
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

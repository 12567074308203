/* NAVBAR STYLING STARTS */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  height: 2.5rem;
  width: calc(100vw - 20px);
  z-index: 10;
  background-color: teal;
  color: #fff;
  ul {
    list-style-type: none;
  }
}

.nav-links {
  padding: 0;
  a {
    color: #fff;
    &.active button,
    &.active {
      text-decoration: underline;
    }
  }
}

/* LOGO */
.logo {
  font-size: 32px;
  @media (max-width: 300px) {
    font-size: 24px;
  }
}

/* NAVBAR MENU */
.menu {
  display: flex;
  gap: 1em;
  @media (max-width: 1140px) {
    gap: initial;
  }
  font-size: 18px;
}

.menu li button:hover {
  background-color: #4c9e9e;
  border-radius: 5px;
  transition: 0.3s ease;
}

.menu li {
  @media (max-width: 1024px) {
    padding: initial;
  }
}

/* RESPONSIVE NAVBAR MENU STARTS */

/* CHECKBOX HACK */

/* HAMBURGER MENU */
button.hamburger {
  display: none;
  font-size: 24px;
  padding: initial;
  user-select: none;
}

/* APPLYING MEDIA QUERIES */
@media (max-width: 855px) {
  .menu {
    display: none;
    position: absolute;
    background-color: teal;
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
  }

  .menu li + li {
    margin-top: 12px;
  }

  button.hamburger[data-value='true'] ~ .menu {
    display: block;
  }

  button.hamburger {
    display: block;
  }
}

.dropdown {
  @media (max-width: 855px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .dropdown_content {
    display: none;
    position: absolute;
    @media (max-width: 855px) {
      position: relative;
      margin: 0.5rem;
    }
    background-color: teal;
    min-width: 160px;
    padding: 0.5rem;
    box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
    a {
      padding: 12px 16px;
      display: block;
      text-decoration: none;
      &:hover {
        background-color: #4c9e9e;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }
}
.dropdown:hover .dropdown_content,
.dropdown:focus .dropdown_content {
  display: block;
}
